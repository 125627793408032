import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BreakoutRoomsSplashScreenName,
  BreakoutRoomsState,
  BreakoutRoomUserJoinedPayload,
  ConfigureBreakoutRoomPayload,
} from 'features/breakout-rooms/types';
import {
  signalingBreakoutRoomAdded,
  signalingBreakoutRoomDeleted,
  signalingBreakoutRoomRenamed,
  signalingBreakoutRoomsCleared,
  signalingBreakoutRoomsCreated,
  signalingBreakoutRoomsReceived,
  signalingBreakoutRoomsStarted,
  signalingBreakoutRoomsStopped,
  signalingBreakoutUserJoined,
  signalingBreakoutUserLeft,
  signalingBreakoutUserLeftBatch,
  signalingUsersAssignedToBreakoutRoom,
  signalingUserUnassignedFromBreakoutRoom,
} from 'features/breakout-rooms/actions';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { RootState } from 'store/store';
import { changeRole, selectActiveUserById, userLeft } from 'features/users/usersSlice';
import { signalingRoomJoined } from 'features/room/actions';
import { User, UserId } from 'features/users/types';
import { selectBreakoutUser } from 'features/breakout-rooms/selectors/selectBreakoutUser';
import { userNameDecrypted } from 'features/e2ee/actions';

export const initialState: BreakoutRoomsState = {
  breakout: false,
  movingBetweenRooms: false,
  status: 'idle',
  splash: {
    show: false,
    openedTimestamp: 0,
    screenName: 'joining',
  },
  roomsLimit: 0,
  participantsLimit: 0,
  byId: {},
  allIds: [],
  users: {},
  roomByUser: {},
  unassignedUsers: {},
  pendingMetaUsersByRoom: {},
};

const unassignFromRoom = (
  state: BreakoutRoomsState,
  userId: UserId,
  roomId?: string,
  skipMainRoomAssignment = false
) => {
  const user = state.users[userId];
  if (!user) {
    // safeguard for attendees who receive this event in ongoing breakouts;
    return;
  }

  if (!skipMainRoomAssignment) {
    state.unassignedUsers[user.id] = user;
  }

  if (roomId) {
    const currentRoomId = state.roomByUser[userId];
    if (roomId === currentRoomId) {
      // ensure we unassign the user from the correct room
      delete state.roomByUser[userId];
      delete state.users[userId];
    }

    const room = state.byId[roomId];
    if (room) {
      const index = room.users.indexOf(userId);
      if (index > -1) {
        // mutate the object for performance
        room.users.splice(index, 1);
      }
    }
  }
};

const assignToRoom = (state: BreakoutRoomsState, user: User, roomId: string) => {
  if (!state.byId[roomId]) {
    // bypass race condition when breakoutUserJoined could be received before roomJoined
    state.pendingMetaUsersByRoom[roomId] = user;

    return;
  }

  if (state.users[user.id]) {
    const previousRoomId = state.roomByUser[user.id];
    unassignFromRoom(state, user.id, previousRoomId);
  }

  state.users[user.id] = user;
  state.roomByUser[user.id] = roomId;

  if (!state.byId[roomId].users.includes(user.id)) {
    state.byId[roomId].users.push(user.id);
  }

  delete state.unassignedUsers[user.id];
};

export const breakoutRoomsSlice = createSlice({
  name: 'breakoutRooms',
  initialState,
  reducers: {
    roomsConfigured(state, action: PayloadAction<ConfigureBreakoutRoomPayload>) {
      const {
        normalizedState: { roomByUser, byId, users, allIds },
        unassignedUsers,
      } = action.payload;

      Object.assign(state, { byId, allIds, roomByUser, users, unassignedUsers });

      // sync user from premature breakoutUserJoined event
      const pendingMetaUsers = Object.entries(state.pendingMetaUsersByRoom);
      if (pendingMetaUsers.length) {
        for (const [roomId, user] of pendingMetaUsers) {
          console.error(`syncing breakout users! userId=${user.id} roomId=${roomId}`);
          assignToRoom(state, user, roomId);
        }
        state.pendingMetaUsersByRoom = {};
      }
    },
    cleanBreakoutRooms(state) {
      // @TODO merge it with signalingBreakoutRoomsCleared reducer?
      state.byId = {};
      state.allIds = [];
      state.users = {};
      state.roomByUser = {};
      state.unassignedUsers = {};
    },
    roomUserJoined(state, action: PayloadAction<BreakoutRoomUserJoinedPayload>) {
      const { user, roomId } = action.payload;
      assignToRoom(state, user, roomId);
    },
    unassignedUserJoined(state, action: PayloadAction<User>) {
      state.unassignedUsers[action.payload.id] = action.payload;
    },
    splashScreenOpened(state, action: PayloadAction<BreakoutRoomsSplashScreenName>) {
      state.splash.screenName = action.payload;
      state.splash.show = true;
      state.splash.openedTimestamp = Number(new Date());
    },
    splashScreenClosed(state) {
      state.splash.show = false;
    },
    roomMovementRequested(state) {
      state.movingBetweenRooms = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorizeUser.fulfilled, (state, action) => {
        const { breakoutRooms } = action.payload;
        if (breakoutRooms) {
          state.roomsLimit = breakoutRooms.roomsLimit;
          state.participantsLimit = breakoutRooms.participantsLimit;
        }
      })
      .addCase(signalingRoomJoined, (state, action) => {
        const { breakout, breakoutRooms } = action.payload;
        state.breakout = breakout;

        if (breakoutRooms?.started) {
          state.status = 'started';
        } else if (state.status === 'ended') {
          state.status = 'created';
        } else {
          state.status = 'idle';
        }

        if (state.movingBetweenRooms) {
          state.movingBetweenRooms = false;
        }
      })
      .addCase(signalingBreakoutRoomsReceived, (state, action) => {
        if (action.payload.started) {
          state.status = 'started';
        }
      })
      .addCase(signalingBreakoutRoomsCleared, (state) => {
        state.byId = {};
        state.allIds = [];
        state.users = {};
        state.roomByUser = {};
        state.unassignedUsers = {};
      })
      .addCase(signalingBreakoutRoomDeleted, (state, action) => {
        const room = state.byId[action.payload.id];

        for (const userId of room.users) {
          unassignFromRoom(state, userId);

          delete state.users[userId];
          delete state.roomByUser[userId];
        }

        state.allIds = state.allIds.filter((id) => id !== room.id);
        delete state.byId[room.id];
      })
      .addCase(signalingBreakoutRoomRenamed, (state, action) => {
        const room = state.byId[action.payload.id];

        if (room) {
          room.name = action.payload.name;
        }
      })
      .addCase(userLeft, (state, action) => {
        // this reducer is only used to manage breakouts panel in the main room
        if (!state.breakout) {
          for (const { userId, roomId } of action.payload) {
            const currentRoomId = state.roomByUser[userId];

            // @TODO FIXME remove test code
            if (currentRoomId !== roomId) {
              console.error(`bug with missing user on user left fixed userId=${userId}`);
            }
            if (currentRoomId === roomId) {
              unassignFromRoom(state, userId, currentRoomId);
            }

            delete state.unassignedUsers[userId];
          }
        }
      })
      .addCase(signalingBreakoutRoomsCreated, (state, action) => {
        if (action.payload.started) {
          state.status = 'started';
        } else {
          state.status = 'created';
        }
      })
      .addCase(signalingBreakoutRoomAdded, (state, action) => {
        const { id, name } = action.payload;

        state.byId[id] = { id, name, users: [] };
        state.allIds.push(id);
      })
      .addCase(signalingBreakoutRoomsStarted, (state, action) => {
        state.status = 'started';
        state.targetRoom = action.payload?.targetRoom;
      })
      .addCase(signalingBreakoutRoomsStopped, (state) => {
        state.status = 'ended';

        state.targetRoom = undefined;
      })
      .addCase(signalingUsersAssignedToBreakoutRoom, (state, action) => {
        const { id: roomId, users } = action.payload;
        for (const userId of users) {
          // unassign from a previous room if any
          const user = state.users[userId];
          if (user) {
            const previousRoomId = state.roomByUser[userId];
            unassignFromRoom(state, userId, previousRoomId);
          }

          // assign to a new room
          state.roomByUser[userId] = roomId;
          state.byId[roomId].users.push(userId);

          const unassignedUser = state.unassignedUsers[userId];
          state.users[unassignedUser.id] = unassignedUser;

          delete state.unassignedUsers[userId];
        }
      })
      .addCase(signalingUserUnassignedFromBreakoutRoom, (state, action) => {
        const { id: roomId, userId } = action.payload;
        unassignFromRoom(state, userId, roomId);
      })
      .addCase(signalingBreakoutUserJoined, (state, action) => {
        const { id: roomId, user } = action.payload;
        if (roomId) {
          assignToRoom(state, user, roomId);
        } else {
          state.unassignedUsers[user.id] = user;

          delete state.users[user.id];
          delete state.roomByUser[user.id];
        }
      })
      .addCase(signalingBreakoutUserLeft, (state, action) => {
        const { id: roomId, userId } = action.payload;
        unassignFromRoom(state, userId, roomId, true);
        if (!roomId) {
          delete state.unassignedUsers[userId];
        }
      })
      .addCase(signalingBreakoutUserLeftBatch, (state, action) => {
        const { users } = action.payload;
        for (const { id: roomId, userId } of users) {
          unassignFromRoom(state, userId, roomId, true);
          if (!roomId) {
            delete state.unassignedUsers[userId];
          }
        }
      })
      .addCase(changeRole, (state, action) => {
        if (state.users[action.payload.id]) {
          state.users[action.payload.id].role = action.payload.role;
        }

        if (state.unassignedUsers[action.payload.id]) {
          state.unassignedUsers[action.payload.id].role = action.payload.role;
        }
      })
      .addCase(userNameDecrypted, (state, action) => {
        const { id, name } = action.payload;
        const breakoutUser = state.users[id];
        if (breakoutUser) {
          breakoutUser.name = name;
        }

        const unassignedUser = state.unassignedUsers[id];
        if (unassignedUser) {
          unassignedUser.name = name;
        }
      });
  },
});

export default breakoutRoomsSlice.reducer;

export const {
  roomsConfigured,
  cleanBreakoutRooms,
  roomUserJoined,
  unassignedUserJoined,
  splashScreenOpened,
  splashScreenClosed,
  roomMovementRequested,
} = breakoutRoomsSlice.actions;

export const selectIsBreakoutRoom = (state: RootState) => state.breakoutRooms.breakout;

export const selectBreakoutRoomsLimit = (state: RootState) => state.breakoutRooms.roomsLimit;

export const selectBreakoutParticipantsLimit = (state: RootState) =>
  state.breakoutRooms.participantsLimit;

export const selectBreakoutRoomsEntries = (state: RootState) => state.breakoutRooms.byId;

export const selectAllBreakoutRoomsIds = (state: RootState) => state.breakoutRooms.allIds;

export const selectBreakoutRoomsUsers = (state: RootState) => state.breakoutRooms.users;

export const selectBreakoutsUnassignedUserEntries = (state: RootState) =>
  state.breakoutRooms.unassignedUsers;

export const selectBreakoutRoom = (state: RootState, id: string) => state.breakoutRooms.byId[id];

export const selectBreakoutRoomsCreated = (state: RootState) => {
  const allIds = selectAllBreakoutRoomsIds(state);

  return allIds.length > 0;
};

export const selectBreakoutRoomsStatus = (state: RootState) => state.breakoutRooms.status;

export const selectBreakoutTargetRoom = (state: RootState) => state.breakoutRooms.targetRoom;

export const selectUserBreakoutRoomId = (state: RootState, userId: UserId) =>
  state.breakoutRooms.roomByUser[userId];

export const selectUserInBreakoutRoom = (state: RootState, roomId: string) =>
  state.breakoutRooms.roomByUser[state.users.localUserId] === roomId;

export const selectBreakoutRoomHasSlots = (state: RootState, roomId: string) =>
  state.breakoutRooms.byId[roomId]
    ? state.breakoutRooms.byId[roomId].users.length < state.breakoutRooms.participantsLimit
    : false;

export const selectShowBreakoutsSplash = (state: RootState) => state.breakoutRooms.splash.show;

export const selectBreakoutsSplashOpenedAt = (state: RootState) =>
  state.breakoutRooms.splash.openedTimestamp;

export const selectBreakoutSplashScreenName = (state: RootState) =>
  state.breakoutRooms.splash.screenName;

export const selectActiveBreakoutUser = createSelector(
  [selectActiveUserById, selectBreakoutUser],
  (appUser, breakoutUser) => appUser || breakoutUser
);

export const selectMovingBetweenRooms = (state: RootState) =>
  state.breakoutRooms.movingBetweenRooms;

export const selectPendingBreakoutMetaUsers = (state: RootState) =>
  state.breakoutRooms.pendingMetaUsersByRoom;
