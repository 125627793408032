import { call, fork } from 'redux-saga/effects';
import { initSframeClientSaga } from 'features/e2ee/sagas/initSframeClientSaga';
import { generateRoomE2eeCode } from 'features/e2ee/sagas/generateRoomE2eeCode';
import { bulkExchangeEncryptionKeysSaga } from 'features/e2ee/sagas/bulkExchangeEncryptionKeysSaga';
import { bulkComputeEncryptionKeysSaga } from 'features/e2ee/sagas/bulkComputeEncryptionKeysSaga';
import { exchangeEncryptionKeysSaga } from 'features/e2ee/sagas/exchangeEncryptionKeysSaga';
import { RoomJoinedPayload } from 'features/room/types';

export function* setupE2eeSaga(data: RoomJoinedPayload) {
  yield fork(generateRoomE2eeCode);

  const {
    users,
    usersAwaitingEntry,
    user: { e2eeId },
    recorder,
    roomId,
  } = data;

  yield call(initSframeClientSaga, e2eeId!);

  yield fork(bulkExchangeEncryptionKeysSaga, users);

  if (recorder) {
    yield fork(exchangeEncryptionKeysSaga, {
      id: recorder.id,
      roomId,
      e2eePublicKey: recorder.e2eePublicKey,
    });
  }

  if (usersAwaitingEntry.length) {
    yield call(bulkComputeEncryptionKeysSaga, usersAwaitingEntry);
  }
}
