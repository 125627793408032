import { splashScreenCloseRequested } from 'features/breakout-rooms/actions';
import { selectShowBreakoutsSplash } from 'features/breakout-rooms/breakoutRoomsSlice';
import { signalingRoomJoined } from 'features/room/actions';
import { normalizeBreakoutRoomsSaga } from 'features/breakout-rooms/sagas/normalizeBreakoutRoomsSaga';
import { call, fork, put, select } from 'redux-saga/effects';
import { onRoomJoinConfigSaga } from 'features/room/sagas/onRoomJoinConfigSaga';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { selectRoomLastId } from 'features/room/roomSlice';
import { exchangeEncryptedNameSaga } from 'features/e2ee/sagas/exchangeEncryptedNameSaga';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';

export function* onRoomJoinedSaga(action: ReturnType<typeof signalingRoomJoined>) {
  const { breakoutRooms, roomId, breakoutRoomManagers } = action.payload;

  if (breakoutRooms?.rooms.length) {
    yield call(normalizeBreakoutRoomsSaga, {
      rooms: breakoutRooms.rooms,
      unassignedUsers: breakoutRooms.unassignedUsers,
    });
  }

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled && breakoutRoomManagers) {
    for (const manager of breakoutRoomManagers) {
      try {
        yield fork(exchangeEncryptedNameSaga, manager);
      } catch (error) {
        Sentry.captureException(error);
        logger
          .remote({ system: true, capture: 'e2ee' })
          .error(`Name exchange failed with the userId=${manager.id}`, error);
      }
    }
  }

  const roomLastId: string = yield select(selectRoomLastId);

  // handle only on moving between rooms
  const movingBetweenRooms = roomLastId && roomId !== roomLastId;
  if (!movingBetweenRooms) {
    // ensure we close the splash screen in other cases
    const showSplash: boolean = yield select(selectShowBreakoutsSplash);
    if (showSplash) {
      yield put(splashScreenCloseRequested());
    }
    return;
  }

  if (e2eeEnabled) {
    // regenerate keys for a new room
    yield call(E2EEManager.generateKeys, {
      ellipticCurveKeys: false,
    });
  }

  yield call(onRoomJoinConfigSaga, action.payload);

  const showSplash: boolean = yield select(selectShowBreakoutsSplash);
  if (showSplash) {
    yield put(splashScreenCloseRequested());
  }
}
