import {
  selectBreakoutRoomsStatus,
  selectIsBreakoutRoom,
  splashScreenOpened,
} from 'features/breakout-rooms/breakoutRoomsSlice';
import { normalizeBreakoutRoomsSaga } from 'features/breakout-rooms/sagas/normalizeBreakoutRoomsSaga';
import { call, put, select } from 'redux-saga/effects';
import { signalingBreakoutRoomsReceived } from 'features/breakout-rooms/actions';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { bulkComputeEncryptionKeysSaga } from 'features/e2ee/sagas/bulkComputeEncryptionKeysSaga';
import { User } from 'features/users/types';
import { BreakoutRoomsStatus, NormalizedBreakoutRoomsConfig } from 'features/breakout-rooms/types';
import { selectRoomId } from 'features/room/roomSlice';

export function* onBreakoutRoomsReceivedSaga(
  action: ReturnType<typeof signalingBreakoutRoomsReceived>
) {
  const config: NormalizedBreakoutRoomsConfig = yield call(normalizeBreakoutRoomsSaga, {
    rooms: action.payload.rooms,
    unassignedUsers: action.payload.unassignedUsers,
  });

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  const breakoutRoomsStatus: BreakoutRoomsStatus = yield select(selectBreakoutRoomsStatus);

  if (e2eeEnabled && breakoutRoomsStatus === 'started') {
    const {
      unassignedUsers,
      normalizedState: { roomByUser, users },
    } = config;

    const breakoutUsers: User[] = [];

    const isBreakoutRoom: boolean = yield select(selectIsBreakoutRoom);
    if (isBreakoutRoom) {
      const roomId: string = yield select(selectRoomId);

      for (const user of Object.values(users)) {
        // exclude users from the current breakout room
        if (roomByUser[user.id] !== roomId) {
          breakoutUsers.push(user);
        }
      }

      breakoutUsers.push(...Object.values(unassignedUsers));
    } else {
      // don't collect unassigned users since we are in the main room and know them
      for (const user of Object.values(users)) {
        breakoutUsers.push(user);
      }
    }

    yield call(bulkComputeEncryptionKeysSaga, breakoutUsers);
  }

  if (action.payload.started) {
    yield put(splashScreenOpened);
  }
}
