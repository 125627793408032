import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { streamStarted } from 'features/streaming/actions';
import { RootState } from 'store/store';
import {
  LowerHandPayload,
  RoleChangedPayload,
  UserId,
  UserLeftPayload,
  UsersState,
} from 'features/users/types';
import {
  clearWaitingUsersList,
  signalingHandLowered,
  signalingHandRaised,
  signalingUserJoined,
  signalingWaitingUserJoined,
  signalingWaitingUserLeft,
  signalingWaitingUserLeftBatch,
  signalingWaitingUsersUpdated,
} from 'features/users/actions';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { signalingBroadcasterLimitReached, signalingRoomJoined } from 'features/room/actions';
import { PermissionTypes } from 'features/permissions/types';
import {
  signalingBroadcastAllowed,
  signalingBroadcastDisallowed,
  signalingEditWhiteboardAllowed,
  signalingEditWhiteboardDisallowed,
  signalingScreenshareAllowed,
  signalingScreenshareDisallowed,
} from 'features/permissions/actions';
import { userNameDecrypted } from 'features/e2ee/actions';
import { getUsers, groupUserIdsByRole } from 'features/users/utils';
import { selectBreakoutUser } from 'features/breakout-rooms/selectors/selectBreakoutUser';

export const initialState: UsersState = {
  localUserName: '',
  localUserId: '',
  byId: {},
  availableIds: [],
  activeUsers: {},
  awaitingEntryIds: [],
  broadcastLimitedIds: [],
  handRaisedIds: [],
  activeUserCount: 0,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    userLeft(state, action: PayloadAction<UserLeftPayload[]>) {
      const availableIdsSet = new Set(state.availableIds);
      const handRaisedIdsSet = new Set(state.handRaisedIds);

      for (const { userId } of action.payload) {
        availableIdsSet.delete(userId);
        handRaisedIdsSet.delete(userId);
        delete state.activeUsers[userId];
      }

      state.availableIds = Array.from(availableIdsSet);
      state.handRaisedIds = Array.from(handRaisedIdsSet);
    },
    raiseHand(state) {
      state.byId[state.localUserId].handRaised = true;
      state.handRaisedIds.push(state.localUserId);
    },
    lowerHand(state, action: PayloadAction<LowerHandPayload>) {
      // only handle local user
      if (action.payload === undefined) {
        state.byId[state.localUserId].handRaised = false;
      }
    },
    handRaiseDismissed(state, action: PayloadAction<UserId>) {
      state.byId[action.payload].handRaised = false;
      state.handRaisedIds = state.handRaisedIds.filter((id) => id !== action.payload);
    },
    changeRole(state, action: PayloadAction<RoleChangedPayload>) {
      state.byId[action.payload.id].role = action.payload.role;
    },
    broadcastLimitedDismissed(state) {
      state.broadcastLimitedIds = [];
    },
    userListReset(state) {
      const { localUserId } = state;

      state.availableIds = [localUserId];

      state.byId = {
        [localUserId]: state.byId[localUserId],
      };

      state.activeUsers = {
        [localUserId]: state.activeUsers[localUserId],
      };
    },
    activeUserCountUpdated(state) {
      state.activeUserCount = state.availableIds.length;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signalingUserJoined, (state, action) => {
        const user = action.payload;
        const knownUser = state.byId[user.id];

        if (knownUser) {
          if (knownUser.name) {
            // re-assign user name in e2ee scenario
            user.name = knownUser.name;
          }
          // move the waiting user to a room
          state.awaitingEntryIds = state.awaitingEntryIds.filter((id) => id !== user.id);
        }

        state.availableIds.push(user.id);
        state.availableIds = [...new Set(state.availableIds)];

        state.byId[user.id] = user;
        state.activeUsers[user.id] = true;
      })
      .addCase(signalingRoomJoined, (state, action) => {
        const { id: localUserId } = action.payload.user;

        // override the data for the local user received in the prejoin call
        const localUser = {
          ...(state.byId[localUserId] || {}),
          ...action.payload.user,
          name: state.localUserName,
          dynamicPermissions: action.payload.user.dynamicPermissions,
        };

        state.byId = {
          [localUserId]: localUser,
        };

        state.availableIds = [localUserId];
        state.activeUsers = { [localUserId]: true };
        state.handRaisedIds = localUser.handRaised ? [localUser.id] : [];

        for (const user of action.payload.users) {
          state.byId[user.id] = user;
          state.availableIds.push(user.id);
          state.activeUsers[user.id] = true;

          if (user.handRaised) {
            state.handRaisedIds.push(user.id);
          }
        }

        state.activeUserCount = state.availableIds.length;

        state.awaitingEntryIds = [];
        for (const user of action.payload.usersAwaitingEntry) {
          state.byId[user.id] = user;
          state.awaitingEntryIds.push(user.id);
        }
      })
      .addCase(authorizeUser.fulfilled, (state, action) => {
        const { name: username, initials } = action.meta;

        const localUser = {
          ...action.payload.user,
          name: username,
          local: true,
          initials,
        };

        state.localUserName = username;
        state.localUserId = localUser.id;
        state.byId[localUser.id] = localUser;
        state.availableIds.push(localUser.id);
        state.activeUsers[localUser.id] = true;
      })
      .addCase(userNameDecrypted, (state, action) => {
        const user = state.byId[action.payload.id];
        if (user) {
          user.name = action.payload.name;
        }
      })
      .addCase(signalingHandRaised, (state, action) => {
        const { id } = action.payload.initiator;

        if (state.localUserId !== id) {
          state.byId[id].handRaised = true;
          state.handRaisedIds.push(id);
        }
      })
      .addCase(signalingHandLowered, (state, action) => {
        state.byId[action.payload.id].handRaised = false;
        state.handRaisedIds = state.handRaisedIds.filter((id) => action.payload.id !== id);
      })
      .addCase(signalingBroadcastAllowed, (state, action) => {
        const user = state.byId[action.payload.id];

        user.dynamicPermissions ??= [];
        user.dynamicPermissions.push(PermissionTypes.broadcast);
      })
      .addCase(signalingBroadcastDisallowed, (state, action) => {
        const user = state.byId[action.payload.id];

        if (user.dynamicPermissions) {
          user.dynamicPermissions = user.dynamicPermissions.filter(
            (permission) => permission !== PermissionTypes.broadcast
          );
        }
      })
      .addCase(signalingScreenshareAllowed, (state, action) => {
        const user = state.byId[action.payload.id];

        user.dynamicPermissions ??= [];
        user.dynamicPermissions.push(PermissionTypes.screenshare);
      })
      .addCase(signalingScreenshareDisallowed, (state, action) => {
        const user = state.byId[action.payload.id];

        if (user.dynamicPermissions) {
          user.dynamicPermissions = user.dynamicPermissions.filter(
            (permission) => permission !== PermissionTypes.screenshare
          );
        }
      })
      .addCase(signalingEditWhiteboardAllowed, (state, action) => {
        const user = state.byId[action.payload.id];

        user.dynamicPermissions ??= [];
        user.dynamicPermissions.push(PermissionTypes.editWhiteboard);
      })
      .addCase(signalingEditWhiteboardDisallowed, (state, action) => {
        const user = state.byId[action.payload.id];

        if (user.dynamicPermissions) {
          user.dynamicPermissions = user.dynamicPermissions.filter(
            (permission) => permission !== PermissionTypes.editWhiteboard
          );
        }
      })
      .addCase(signalingWaitingUserJoined, (state, action) => {
        state.byId[action.payload.id] = action.payload;

        state.awaitingEntryIds.push(action.payload.id);
        state.awaitingEntryIds = [...new Set(state.awaitingEntryIds)];
      })
      .addCase(signalingWaitingUserLeft, (state, action) => {
        state.awaitingEntryIds = state.awaitingEntryIds.filter((id) => id !== action.payload.id);
        delete state.byId[action.payload.id];
      })
      .addCase(signalingWaitingUserLeftBatch, (state, action) => {
        const awaitingEntryIdsSet = new Set(state.awaitingEntryIds);

        for (const { id } of action.payload.users) {
          awaitingEntryIdsSet.delete(id);
          delete state.byId[id];
        }

        state.awaitingEntryIds = Array.from(awaitingEntryIdsSet);
      })
      .addCase(clearWaitingUsersList, (state) => {
        for (const id of state.awaitingEntryIds) {
          delete state.byId[id];
        }
        state.awaitingEntryIds = [];
      })
      .addCase(signalingWaitingUsersUpdated, (state, action) => {
        for (const user of action.payload) {
          state.byId[user.id] = user;

          state.awaitingEntryIds.push(user.id);
          state.awaitingEntryIds = [...new Set(state.awaitingEntryIds)];
        }
      })
      .addCase(signalingBroadcasterLimitReached, (state, action) => {
        if (action.payload.id) {
          state.broadcastLimitedIds.push(action.payload.id);
          state.broadcastLimitedIds = [...new Set(state.broadcastLimitedIds)];
        }
      })
      .addCase(streamStarted, (state, action) => {
        state.broadcastLimitedIds = state.broadcastLimitedIds.filter(
          (id) => id !== action.payload.id
        );
      });
  },
});

export const {
  userLeft,
  raiseHand,
  lowerHand,
  changeRole,
  handRaiseDismissed,
  broadcastLimitedDismissed,
  userListReset,
  activeUserCountUpdated,
} = usersSlice.actions;

export default usersSlice.reducer;

export const selectLocalUserId = (state: RootState) => state.users.localUserId;

export const selectUserEntries = (state: RootState) => state.users.byId;

export const selectLocalUser = (state: RootState) => state.users.byId[state.users.localUserId];
export const selectLocalUserRole = (state: RootState) =>
  state.users.byId[state.users.localUserId].role;

export const selectUserById = createSelector(
  [selectUserEntries, (state: RootState, id: UserId) => id],
  (byId, id) => byId[id]
);

// consider breakout user
export const selectGlobalUser = createSelector(
  [selectUserById, selectBreakoutUser],
  (user, breakoutUser) => user || breakoutUser
);

export const selectAvailableUserIds = (state: RootState) => state.users.availableIds;

export const selectActiveUserById = createSelector(
  [
    selectUserEntries,
    (state: RootState) => state.users.activeUsers,
    (state: RootState, id: UserId) => id,
  ],
  (byId, activeUsers, id) => {
    if (activeUsers[id]) {
      return byId[id];
    }

    return undefined;
  }
);

export const selectUsers = createSelector(
  [selectAvailableUserIds, selectUserEntries],
  (availableIds, entries) => getUsers(availableIds, entries)
);

export const selectAllIdsByRole = createSelector(
  [selectAvailableUserIds, selectUserEntries],
  (availableIds, entries) => groupUserIdsByRole(availableIds, entries)
);

export const selectLocalUserHandRaised = (state: RootState) =>
  state.users.byId[state.users.localUserId].handRaised;

export const selectAwaitingEntryIds = (state: RootState) => state.users.awaitingEntryIds;

export const selectBroadcastLimitedIds = (state: RootState) => state.users.broadcastLimitedIds;

export const selectHandRaisedIds = (state: RootState) => state.users.handRaisedIds;

export const selectUserCount = (state: RootState) => state.users.activeUserCount;
